import axios from 'axios'
import DEV_ENV from '../../../../config/dev.env';
import PROD_ENV from '../../../../config/prod.env';

let instance = axios.create({
    headers: {
        common: {
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        }
    }
});

instance.defaults.timeout = 2500;

let GET_ENV = () => {
    return process.env.NODE_ENV === PROD_ENV.NODE_ENV ? PROD_ENV.ROOT_API : DEV_ENV.ROOT_API;
}

let IqosMentolService = () => { GET_ENV() };

IqosMentolService.prototype.getCounties = function () {
    let url = GET_ENV() + '/get-counties/';
    let params = {source: 'Iqos-mentol'};
    return axios.post(url, params, this.getHeaders()).then((response) => response);
};

IqosMentolService.prototype.getCities = function (countyName) {
    let url = GET_ENV() + '/get-cities/';
    let params = {
        source: 'Iqos-mentol',
        county_id: countyName.id
    }
    return axios.post(url, params).then((response) => response);
}

IqosMentolService.prototype.getHeaders = function () {
    return {
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    }
};

export default new IqosMentolService();