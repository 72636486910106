<template>
    <div class="introSlider">
        <div class="slide slide01">
            <div class="container">
                <div class="details">
                    <h1>HEETS MENTOLATE SUNT DISPONIBILE ÎN CONTINUARE,<br/>
                        DUPĂ 15 IUNIE 2020.</h1>
                    <p>Tutun cu note răcoritoare de mentol, acum reimaginat.<br />
                        Descoperă rezervele de tutun HEETS.</p>
                </div>
            </div>
        </div>
        <div class="slide slide02">
            <div class="container desktop">
                <div class="details">
                    <h1> RĂMÂI FRESH </h1>
                    <p>Senzațiile răcoritoare autentice merg mână în mână cu o alternativă inovatoare la fumatul tradițional.</p>
                    <p class="break-paragraph">Descoperă IQOS.</p>
                </div>
            </div>
            <div class="container mobile">
                <div class="details">
                    <h1> RĂMÂI FRESH </h1>
                    <p>Senzațiile răcoritoare autentice merg mână în mână cu o alternativă inovatoare la fumatul tradițional.</p>
                    <p class="break-paragraph">Descoperă IQOS.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Slick from 'vue-slick';

    export default {
        data() {
            return {}
        }
    }
</script>
