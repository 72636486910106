<template>
    <div class="testimonialsVideo">
        <h3>Descoperă experiențele utilizatorilor IQOS</h3>

        <slick class="videos" :options="videoTestimonialsSlider">
            <a href="https://player.vimeo.com/video/434010617" class="video ruxandra" data-lity>
               <div>
                   <p>"Nu a mai mirosit a fum în casă și nu mi-au mai mirosit mâinile"</p>
                   <span>Ruxandra</span>
               </div>
            </a>

            <a href="https://player.vimeo.com/video/434010685" class="video madalina" data-lity>
                <div>
                    <p>“Nu mă mai simt vinovată în momentul in care sunt lânga prieteni care nu fumează"</p>
                    <span>Mădălina</span>
                </div>
            </a>
        </slick>
    </div>
</template>

<script>
    import Slick from 'vue-slick';

    export default {
        name: 'VideoTestimonialComponent',
        components: {Slick},
        data() {
            return {
                videoTestimonialsSlider: {
                    slidesToShow: 2,
                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                draggable: true,
                                arrows: true,
                                prevArrow: '<button type="button" class="slick-prev"></button>',
                                nextArrow: '<button type="button" class="slick-next"></button>',
                                dots: true,
                            }
                        }
                    ]
                }
            }
        },
        mounted() {}
    }
</script>
