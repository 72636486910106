<template>
    <div class="listProducts" id="listProducts">
        <a href="#form" class="scrollDown" @click="scrollDown" />

        <div class="container">
            <h2>EXPERIENȚA FUMATULUI, REINVENTATĂ. <br/>
                ALEGE VARIANTA CARE ȚI SE POTRIVEȘTE.</h2>

            <p>Gama de rezerve de tutun HEETS este adaptată preferințelor tale. Încearcă variantele și alege-o pe cea
                care ți se potrivește cel mai bine. </p>

            <div class="featuredPacks">
                <div class="pack sienna-caps">
                    <img src="/alegefresh/images/heets/heets-sienna-caps.png" alt="heets-sienna-caps">
                    <h3>HEETS Sienna Caps</h3>
                    <p>Robust sau răcoritor</p>

                    <a href="#form" @click="changeSource('AlegeFresh', 'HEETS_Sienna_Caps')">ÎNCEARCĂ IQOS GRATUIT 14 ZILE</a>
                </div>

                <div class="pack turquoise">
                    <img src="/alegefresh/images/heets/heets-turqouise.png" alt="heets-turqouise">
                    <h3>HEETS Turquoise</h3>
                    <p>Fin și răcoritor</p>

                    <a href="#form" @click="changeSource('AlegeFresh', 'HEETS_Turquoise')">ÎNCEARCĂ IQOS GRATUIT 14 ZILE</a>
                </div>

                <div class="pack kelly">
                    <img src="/alegefresh/images/heets/heets-kelly.png" alt="heets-kelly">
                    <h3>HEETS Kelly</h3>
                    <p>Fresh și răcoritor</p>

                    <a href="#form" @click="changeSource('AlegeFresh', 'HEETS_Kelly')">ÎNCEARCĂ IQOS GRATUIT 14 ZILE</a>
                </div>

                <div class="pack blue">
                    <img src="/alegefresh/images/heets/heets-blue.png" alt="heets-blue">
                    <h3>HEETS Blue</h3>
                    <p>Îndrăzneț și răcoritor</p>

                    <a href="#form" @click="changeSource('AlegeFresh', 'HEETS_Blue')">ÎNCEARCĂ IQOS GRATUIT 14 ZILE</a>
                </div>
            </div>

            <div>
                <div class="regularPacks">
                    <div class="pack yellow">
                        <img src="/alegefresh/images/heets/heets-yellow.png" alt="iqos-yellow">
                        <h3>HEETS Yellow</h3>
                    </div>
                    <div class="pack amber">
                        <img src="/alegefresh/images/heets/heets-amber.png" alt="iqos-amber">
                        <h3>HEETS Amber</h3>
                    </div>
                    <div class="pack sienna">
                        <img src="/alegefresh/images/heets/heets-sienna.png" alt="iqos-sienna">
                        <h3>HEETS Sienna</h3>
                    </div>
                    <div class="pack bronze">
                        <img src="/alegefresh/images/heets/heets-bronze.png" alt="iqos-bronze">
                        <h3>HEETS Bronze</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {EventBus} from '../services/event-bus';
    import VueScrollTo from 'vue-scrollto';

    export default {
        methods: {
            changeSource(source, packType){
                EventBus.$emit('change-source', source);

                VueScrollTo.scrollTo("#form", 600, {
                    offset: -50,
                });

                dataLayer.push({
                    'event':'gtmZoneEvent',
                    'eventCategory': 'Clicks',
                    'eventAction': 'Trio_Try',
                    'eventLabel': packType,
                    'eventNonInteraction': 'false'
                });
            },
            scrollDown() {
                VueScrollTo.scrollTo("#listProducts", 600, {
                    offset: -75,
                });
            }
        }
    }
</script>
