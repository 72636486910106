export default {
    noSmoke: [
        {
            name: 'Dan',
            description: 'Imi place ca pot fuma oriunde si oricand, mai ales la volan, sunt foarte fericit ca nu mai am grija fumului neplacut si a scrumatului, este un pas inainte pentru mine IQOS',
            avatar: '/alegefresh/images/man_avatar.png',
            comma: '/alegefresh/images/gray_comma.png',
            borderBottomColor: '13px solid #2B9F61'
        },
        {
            name: 'Ivan',
            description: 'De fiecare dată când doream să fumez o țigară, opream mașina, coboram să fumez, pentru că, deși sunt fumător, detest fumul de țigară. La fel și acasă, fumam la hotă sau ieșeam afară. Cu IQOS nu mai cobor din mașină și nu mai ies din casă.',
            avatar: '/alegefresh/images/man_avatar.png',
            comma: '/alegefresh/images/dark_green_comma.png',
            backgroundColor: '#94CFAF',
            borderColor: '3px solid #2B9F61',
        },
        {
            name: 'Alina',
            description: 'Dimineața îmi începe întotdeauna alături de IQOS. Cafeaua poate mai lipsește dar IQOS niciodată. Ies fericită pe usă elegantă și mirosind a parfum, nu a fum. Bucuroasă că am făcut alegerea IQOS.',
            avatar: '/alegefresh/images/woman_avatar.png',
            comma: '/alegefresh/images/gray_comma.png',
            borderBottomColor: '13px solid #2B9F61'
        },
    ],

    belove: [
        {
            name: 'Cosmin',
            description: 'Îmi place că-mi este mereu alături. Pot utiliza IQOS oriunde, alături de o cafea, alături de prieteni, în pauze la serviciu și în orice împrejurări. Un moment preferat... Dimineață la cafea cu colegii, privindu-mă cumva cu interes și o urmă de „invidie” ☺). Mulțumesc IQOS!',
            avatar: '/alegefresh/images/man_avatar.png',
            comma: '/alegefresh/images/gray_comma.png',
            borderBottomColor: '13px solid #A8CD58'
        },
        {
            name: 'Cristina',
            description: 'Timpul pe care il petrecem alaturi de cei dragi este mai pretios decat orice lucru din lume. Cu IQOS valorifc orice moment fara sa ma departez de cei dragi in momentele in care il folosesc. Cu IQOS am facut o investitie in timp si este de nepretuit!',
            avatar: '/alegefresh/images/man_avatar.png',
            comma: '/alegefresh/images/green_comma.png',
            backgroundColor: '#D3E6AB',
            borderColor: '3px solid #D3E6AB'
        },
        {
            name: 'Andreea',
            description: 'De cand utilizez IQOS petrec mai mult timp cu prietenii nu mai este nevoia sa ies afara la tigara si sa pierd momentele cu cei dragi si mai ales am scapat de morosul de tutun impregnat in haine si in  par. IQOS este o alternativa mai buna si mi-a schimbat viata in mai bine. IQOS LOVER <3',
            avatar: '/alegefresh/images/woman_avatar.png',
            comma: '/alegefresh/images/gray_comma.png',
            borderBottomColor: '13px solid #A8CD58'
        },
    ],

    heatTobacco: [
        {
            name: 'Laura',
            description: 'Momentul meu preferat cu IQOS este seara cand ajung acasa si stau alaturi de familia mea... La o poveste de seara.',
            avatar: '/alegefresh/images/woman_avatar.png',
            comma: '/alegefresh/images/gray_comma.png',
            borderBottomColor: '13px solid #34909E'
        },
        {
            name: 'Cristina',
            description: 'Am inceput sa utilizez IQOS acum aproape 1 an de zile. Decizia am luat-o deoarece imi doream sa scap de acel miros urat pe maini, haine si sa nu mai vorbim de respiratie. Am testat IQOS inainte de a-mi achizitiona aparatul de la cativa prieteni si mi s-a parut ca mi se potriveste. Si chiar asa a si fost. De aproape 12 luni utilizez in  mod exclusiv IQOS si sunt foarte multumita de el.',
            avatar: '/alegefresh/images/woman_avatar.png',
            comma: '/alegefresh/images/blue_comma.png',
            backgroundColor: '#99C7CE',
            borderColor: '3px solid #34909E'
        },
        {
            name: 'Remus',
            description: 'Unul dintre principalele momente preferate zilnice privind dispozitivul IQOS este ca pot purta o conversație frumoasa și decenta fără a mirosi a fum de tigara',
            avatar: '/alegefresh/images/man_avatar.png',
            comma: '/alegefresh/images/gray_comma.png',
            borderBottomColor: '13px solid #34909E'
        },
    ],
};