<template>
    <div class="leadGeneration" id="form">
        <div class="container">
            <h2><b>Rămâi fresh!</b></h2>
            <p><strong>Încearcă și tu IQOS gratuit, timp de 14 zile, la tine acasă.</strong> E foarte simplu:
                completează formularul de mai jos (durează doar câteva secunde), iar un reprezentant IQOS te va contacta
                în cel mai scurt timp pentru a-ți trimite dispozitivul acasă.</p>
            <p>Ce primesc?</p>
            <ul>
                <li>Un dispozitiv IQOS 2.4+ nou, sigilat, pe care îl poți încerca gratuit 14 zile.</li>
                <li>Un pachet de rezerve de tutun HEETS, la alegere, odată cu livrarea dispozitivului.</li>
            </ul>
            <p>Dacă la sfârșitul celor 14 zile nu dorești să achiziționezi dispozitivul, vei fi contactat de un
                reprezentant IQOS pentru a-l returna în termen de maxim 10 zile.</p>
            <p>Valabil doar dacă ești fumător adult (peste 18 ani), rezident în România.</p>
            <div class="formWrapper">
                <img src="/alegefresh/images/ui/device-teal.png" alt="iqos-teal">

                <div class="form">
                    <form v-show="!showSuccess" @submit.prevent="validateBeforeSubmit"
                          :class="{'isLoading': isLoading}">
                        <label :class="{'hasError': errors.has('name')}" @click="startForm">
                            <p>Prenume *</p>
                            <input type="text" name="name" v-model="name" v-validate="'required'">
                            <span v-show="errors.has('name')" class="error">{{ errors.first('name') }}</span>
                        </label>

                        <label :class="{'hasError': errors.has('surname')}">
                            <p>Nume *</p>
                            <input type="text" name="surname" v-model="surname" v-validate="'required'">
                            <span v-show="errors.has('surname')" class="error">{{ errors.first('surname') }}</span>
                        </label>

                        <label :class="{'hasError': errors.has('phone')}">
                            <p>Număr de telefon *</p>
                            <input type="tel" name="phone" v-model="phone" v-validate="'required|numeric|max:10'"
                                   maxlength="10"/>
                            <span v-show="errors.has('phone')" class="error">{{ errors.first('phone') }}</span>
                        </label>

                        <label :class="{'hasError': errors.has('email')}">
                            <p>E-mail *</p>
                            <input type="text" name="email" v-model="email" v-validate="'required|email'">
                            <span v-show="errors.has('email')" class="error">{{ errors.first('email') }}</span>
                        </label>

                        <label :class="{'hasError': errors.has('county')}">
                            <p>Alege județul</p>
                            <v-select class="vue-select-default"
                                      v-if="counties"
                                      :options="counties"
                                      label="county"
                                      @input="getCities"
                                      v-model="county"
                                      name="county"
                                      ref="select"
                                      v-validate="'required'">
                                <span slot="no-options" @click="$refs.select.open = false">Nu sunt rezultate</span>
                            </v-select>
                            <span v-show="errors.has('county')" class="error">{{ errors.first('county') }}</span>
                        </label>

                        <label :class="{'hasError': true, 'has-error': errors.has('city')}">
                            <p>Alege orașul</p>
                            <v-select class="vue-select-default"
                                      :options="cities"
                                      label="city"
                                      v-model="city"
                                      name="city"
                                      ref="select"
                                      v-validate="'required'">
                                <span slot="no-options" @click="$refs.select.open = false">Nu sunt rezultate</span>
                            </v-select>
                            <span v-show="errors.has('city')" class="error">{{ errors.first('city') }}</span>
                        </label>

                        <label class="control control--checkbox">
                            <input type="checkbox" name="is_adult" v-model="is_adult" v-validate="'required'"/>
                            <div class="control__indicator"></div>
                            <p>Declar că am peste 18 ani, sunt fumător și doresc să fiu contactat de un reprezentant
                                IQOS.</p>
                            <span v-show="errors.has('is_adult')" class="error">{{ errors.first('is_adult') }}</span>
                        </label>

                        <label :class="{'hasError': errors.has('errorMessage')}" style="margin: 0; width: 100%;">
                            <span class="error">{{ errors.first('errorMessage') }}</span>
                        </label>

                        <button type="submit">Trimite</button>
                    </form>

                    <div class="success" v-show="showSuccess">
                        <h2>Îți mulțumim!</h2>
                        <p>În cel mai scurt timp, unul dintre colegii noștri te va contacta pentru stabilirea unei
                            întâlniri!
                            Până atunci, descoperă mai multe despre experiența fumatului, reinventată!</p>
                        <a href="#beneficii" class="primaryButton">Află mai multe despre IQOS</a>
                    </div>

                    <p>*Valabil doar dacă ești fumător adult (peste 18 ani), rezident în România.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Validator} from 'vee-validate';
import {EventBus} from '../services/event-bus';
import MaskedInput from 'vue-masked-input';
import IqosMentolService from '../services/mentol-service';
import 'vue-select/dist/vue-select.css';

let dictionary = {
    en: {
        messages: {
            required: () => 'Acest câmp este obligatoriu.',
            email: () => 'Adresa de email nu este validă.',
            min: (s, s2) => 'Acest câmp trebuie să conțină minim ' + s2 + ' caractere.',
            max: (s, s2) => 'Acest câmp trebuie să conțină maxim ' + s2 + ' caractere.',
            numeric: () => 'Te rugăm să introduci un număr de telefon valid.'
        }
    }
};

Validator.localize('en', dictionary.en);

export default {
    validator: null,
    components: {
        MaskedInput
    },
    mounted() {
        this.getCounties();
        EventBus.$on('change-source', (response) => {
            this.source = response;
        });
    },
    data() {
        return {
            showSuccess: false,
            isLoading: false,
            counties: null,
            county: null,
            cities: [],
            city: null,
            name: '',
            surname: '',
            phone: '',
            email: '',
            source: 'AlegeFresh',
            is_adult: ''
        };
    },
    watch: {
        county: function () {
            this.cities = [];
            this.$emit("input",this.city = null);
            this.errors.clear();
        }
    },
    methods: {
        getCounties() {
            IqosMentolService.getCounties().then((response) => {
                this.counties = response.data.content;
            });
        },
        getCities: function (countyName) {
            if (countyName !== null) {
                IqosMentolService.getCities(countyName).then(response => {
                    let cities = response.data.content[0].cities;
                    cities.map(city => this.cities.push(city))
                })
                    .catch((error) => {
                        this.showLoader = false;
                        this.errors.add({
                            field: 'errorMessage',
                            msg: 'A apărut o eroare neprevazută. Te rugăm încearcă din nou mai târziu.'
                        });
                        this.hasErrors = true;
                    });
            }
            return;
        },

        startForm() {
            dataLayer.push({
                'event': 'gtmZoneEvent',
                'eventCategory': 'Form',
                'eventAction': 'Try_Form_AF',
                'eventLabel': 'Form Start',
                'eventNonInteraction': 'false'
            });
        },
        validateBeforeSubmit() {
            this.isLoading = true;

            this.$validator.validateAll()
                .then((result) => {
                    this.isLoading = false;

                    if (result) {
                        this.isLoading = true;

                        dataLayer.push({
                            'event': 'gtmZoneEvent',
                            'eventCategory': 'Form',
                            'eventAction': 'Try_Form_AF',
                            'eventLabel': 'Form Finish',
                            'eventNonInteraction': 'false'
                        });

                        axios.post('/alegefresh/api/submit-form', {
                            name: this.name,
                            surname: this.name,
                            phone: this.phone,
                            email: this.email,
                            county: this.county.id,
                            city: this.city.id,
                            is_adult: this.is_adult,
                            source: this.source
                        })
                            .then((response) => {
                                this.isLoading = false;
                                this.showSuccess = true;
                                EventBus.$emit('show-success-popup');

                                dataLayer.push({
                                    'event': 'gtmZoneEvent',
                                    'eventCategory': 'Form',
                                    'eventAction': 'Try_Form_AF',
                                    'eventLabel': 'Form Complete',
                                    'eventNonInteraction': 'false'
                                });
                            })
                            .catch((error) => {
                                this.isLoading = false;
                                this.errors.clear();

                                for (let item in error.response.data.errors) {
                                    this.errors.add({
                                        field: item,
                                        msg: error.response.data.errors[item][0],
                                    });
                                }

                                dataLayer.push({
                                    'event': 'gtmZoneEvent',
                                    'eventCategory': 'Form',
                                    'eventAction': 'Try_Form_AF',
                                    'eventLabel': 'Form Error - backend',
                                    'eventNonInteraction': 'false'
                                });
                            });
                        return;
                    } else {
                        dataLayer.push({
                            'event': 'gtmZoneEvent',
                            'eventCategory': 'Form',
                            'eventAction': 'Try_Form_AF',
                            'eventLabel': 'Form Error - typo',
                            'eventNonInteraction': 'false'
                        });
                    }
                    return;
                });
        },
    },
}
</script>

