<template>
    <div class="counter-container">
        <div class="counter">
            <countdown :time="time" :transform="transform">
                <template slot-scope="props">
                    <div class="counter-items">
                        <span class="item">
                            <span class="number">{{props.days}}</span>
                            <span class="text">{{props.days > 1 ? 'zile' : 'zi' }}</span>
                        </span>
                        <span class="dots">:</span>
                        <span class="item">
                            <span class="number">{{props.hours}}</span>
                            <span class="text">{{props.hours > 1 ? 'ore' : 'ora'}}</span>
                        </span>
                        <span class="dots">:</span>
                        <span class="item">
                            <span class="number">{{props.minutes}}</span>
                            <span class="text">min</span>
                        </span>
                        <span class="dots">:</span>
                        <span class="item">
                            <span class="number">{{props.seconds}}</span>
                            <span class="text">sec</span>
                        </span>
                    </div>
                </template>
            </countdown>
        </div>
        <span class="disclaimer">INTERZICEREA VÂNZĂRII ȚIGĂRILOR MENTOLATE ȘI CU CAPSULĂ</span>
    </div>
</template>

<script>
    export default {
        name: "CounterComponent",
        data() {
            return {
                time: null
            }
        },
        methods: {
            transform(props) {
                Object.entries(props).forEach(([key, value]) => {
                    props[key] = value < 10 ? `0${value}` : value;
                });

                return props;
            }
        },
        mounted() {
            const endDate = new Date('2020-06-14T23:59:59');
            this.time = endDate - new Date();
        }
    }
</script>

<style scoped>

</style>