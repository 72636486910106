import VeeValidate from 'vee-validate';
import VueScrollTo from 'vue-scrollto';
import vueDebounce from 'vue-debounce';

import '@babel/polyfill';
let Promise = require('es6-promise').Promise;
require('es6-promise').polyfill();

require('./bootstrap');

import Vue from 'vue';
import VueCountdown from '@chenfengyuan/vue-countdown';
import vSelect from 'vue-select';

Vue.component('slider-component', require('./components/SliderComponent').default);
Vue.component('product-component', require('./components/ProductsComponent').default);
Vue.component('lead-generation-component', require('./components/LeadGenerationComponent').default);
Vue.component('benefits-component', require('./components/BenefitsComponent').default);
Vue.component('faq-component', require('./components/FaqsComponent').default);
Vue.component('support-component', require('./components/SupportComponent').default);
Vue.component('success-popup', require('./components/SuccessPopup').default);
Vue.component('list-search-results', require('./components/ListSearchResults').default);
Vue.component('counter-component', require('./components/CounterComponent').default);
Vue.component('selected-search-result', require('./components/SelectedSearchResult').default);
Vue.component('testimonials-component', require('./components/ucg/TestimonialsComponent').default);
Vue.component('video-testimonials-component', require('./components/ucg/VideoTestimonialComponent').default);
Vue.component('v-select', vSelect);

Vue.use(VeeValidate);
Vue.use(VueScrollTo);
Vue.use(vueDebounce);

Vue.component(VueCountdown.name, VueCountdown);

Vue.filter('truncate', function (value, limit) {
    if (value.length > limit) {
        value = value.substring(0, (limit - 3)) + '...';
    }

    return value
});

const app = new Vue({
    el: '#app',
});