<template>
    <div>
        <div :class="{'searchWrapper': true, 'noIcon': searchValue}">
            <vue-bootstrap-typeahead
                    :data="faqs"
                    v-model="searchValue"
                    :serializer="s => s.question"
            />
            <ul class="listSearchTags">
                <li v-for="tag in tags" @click="selectTag(tag)" :class="{'isSelected': selectedTag === tag}">{{tag}}</li>
            </ul>
        </div>

        <h3 id="faqsList">Vezi răspunsurile pentru căutarea ta.</h3>

        <selected-search-result />

        <div class="listSearchResults">
            <div v-for="(faq, index) in faqs" :class="{'result': true}" v-show="index < displayedFaqs">
                <h3>{{ faq.question | truncate(70) }}</h3>
                <p>{{ faq.answer | truncate(150) }}</p>
                <button @click="showMore(faq, index)">Citește mai mult</button>

                <ul class="listTags">
                    <li v-for="tag in faq.tags" @click="selectTag(tag.name)">{{ tag.name }}</li>
                </ul>
            </div>
        </div>

        <button @click="viewMore" class="viewMore" v-if="displayedFaqs < faqs.length">
            Vezi mai <br />multe rezultate
            <i class="fa fa-chevron-down" />
        </button>
    </div>
</template>

<script>
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
    import {EventBus} from "../services/event-bus";
    import VueScrollTo from "vue-scrollto";

    export default {
        mounted() {
            this.getArticles(null, null);
        },
        components: {
            VueBootstrapTypeahead
        },
        data() {
            return {
                tags: [],
                faqs: [],
                searchValue: null,
                displayedFaqs: 9,
                selectedTag: null,
                hasSavedInitialFaqs: false
            }
        },
        methods: {
            showMore(question, index) {
                EventBus.$emit('select-question', question, index);
                VueScrollTo.scrollTo("#faqsList", 600, {
                    offset: -100,
                });
            },
            viewMore(){
                this.displayedFaqs += 6;
            },
            selectTag(tag) {
                if (this.selectedTag === tag) {
                    this.selectedTag = null;
                } else {
                    this.selectedTag = tag;
                }
                this.getArticles(this.searchValue, this.selectedTag);
                // VueScrollTo.scrollTo(".faqsComponent", 600, {
                //     offset: -100,
                // });
            },
            getArticles(searchValue, tag) {
                axios.get('/alegefresh/api/faqs', {
                    params: {
                        searchValue,
                        tags: tag !== null ? [tag] : []
                    }
                }).then((res) => {
                    this.faqs = res.data;
                    if (this.hasSavedInitialFaqs === false) {
                        this.getTags(res.data)
                    }
                    this.hasSavedInitialFaqs = true;
                });
            },
            getTags(data) {
                data.map((question) => {
                    question.tags.map((tag) => {
                        this.tags.push(tag.name);
                        return tag;
                    });
                    return question;
                });
                this.tags = this.tags.filter((tag, index) => this.tags.indexOf(tag) === index);
            }
        },
        watch: {
            searchValue: _.debounce(function (searchValue) {
                this.getArticles(searchValue, this.selectedTag);
            }, 500)
        }
    }
</script>

<link href="https://unpkg.com/vue-bootstrap-typeahead/dist/VueBootstrapTypeahead.css" rel="stylesheet" />
