<template>
    <div :class="dynamicClass" v-if="isVisible">
        <button class="close" @click="toggle" />

        <h3>{{ question.question }}</h3>
        <p>{{ question.answer }}</p>
        <ul class="listTags">
            <li v-for="tag in question.tags">{{ tag.name }}</li>
        </ul>
    </div>
</template>

<script>
    import {EventBus} from "../services/event-bus";

    export default {
        data() {
            return {
                isVisible: false,
                showMoreColor: [],
                truncated: true,
                question: ''
            }
        },
        mounted() {
            EventBus.$on('select-question', (question, index) => {
                this.question = question;
                this.truncated = true;
                this.isVisible !== true && this.toggle();
                this.changeSelectedColor(index);
            });
            window.onresize = () => {
                this.windowWidth = window.innerWidth
            }
        },
        computed: {
            dynamicClass: function () {
                return {
                    'selectedSearchResult': true,
                    [this.showMoreColor]: true,
                    windowWidth: window.innerWidth
                }
            }
        },
        methods: {
            toggle() {
                this.isVisible = !this.isVisible;
            },
            changeSelectedColor(index){
                if (this.windowWidth <= 1040) {
                    switch (index % 3) {
                        case 0:
                            this.showMoreColor = 'color1';
                            break;
                        case 1:
                            this.showMoreColor = 'color2';
                            break;
                        case 2:
                            this.showMoreColor = 'color3';
                            break;
                        default:
                            this.showMoreColor = 'defaultColor';
                            break;
                    }
                } else {
                    switch (index % 9) {
                        case 0:
                            this.showMoreColor = 'color1';
                            break;
                        case 1:
                            this.showMoreColor = 'color2';
                            break;
                        case 2:
                            this.showMoreColor = 'color3';
                            break;
                        case 3:
                            this.showMoreColor = 'color2';
                            break;
                        case 4:
                            this.showMoreColor = 'color3';
                            break;
                        case 5:
                            this.showMoreColor = 'color1';
                            break;
                        case 6:
                            this.showMoreColor = 'color3';
                            break;
                        case 7:
                            this.showMoreColor = 'color1';
                            break;
                        case 8:
                            this.showMoreColor = 'color2';
                            break;
                        default:
                            this.showMoreColor = 'defaultColor';
                            break;
                    }
                }
            },
            showMore() {
                this.truncated = false;
            }
        }
    }
</script>
