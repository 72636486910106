<template>
    <div class="testimonialsSlider">
        <slick class="boxes"
               :options="testimonialsSlider" v-if="currentCategory.length > 0"
        >
            <div v-for="(person, index) in currentCategory"
                 :class="{'box': true, 'odd': index % 2 === 0}"
                 v-if="index % 2 === 0">

                <div class="description" :style="{borderBottom: person.borderBottomColor}">
                    <span class="comma">
                        <img :src="person.comma" alt="Comma">
                    </span>
                    <h3>{{ person.description }}</h3>
                </div>
                <div class="avatar">
                    <div>
                        <img :src="person.avatar" alt="Avatar">
                        <p>{{ person.name }}</p>
                    </div>
                </div>
            </div>

            <div v-else :class="{'box': true, 'even': index % 2 === 1}">
                <div class="avatar" :style="{backgroundColor: person.backgroundColor}">
                    <div>
                        <img :src="person.avatar" alt="Avatar">
                        <p>{{ person.name }}</p>
                    </div>
                </div>
                <div class="description">
                        <span class="comma">
                            <img :src="person.comma" alt="Comma">
                        </span>
                    <h3 :style="{borderLeft: person.borderColor}">{{ person.description }}</h3>
                </div>
            </div>
        </slick>
    </div>
</template>

<script>
    import Slick from 'vue-slick';
    import Testimonials from './Testimonials';

    export default {
        name: 'TestimonialsComponent',
        components: {Slick},
        props: ['category'],

        data() {
            return {
                currentCategory: [],

                testimonialsSlider: {
                    slidesToShow: 3,

                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1,
                                draggable: true,
                                arrows: true,
                                prevArrow: '<button type="button" class="slick-prev"></button>',
                                nextArrow: '<button type="button" class="slick-next"></button>',
                                dots: true,
                            }
                        },

                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                draggable: true,
                                arrows: true,
                                prevArrow: '<button type="button" class="slick-prev"></button>',
                                nextArrow: '<button type="button" class="slick-next"></button>',
                                dots: true,
                            }
                        }
                    ]
                }
            }
        },
        mounted() {
            this.currentCategory = Testimonials[this.category];
        }
    }
</script>
