<template>
    <div class="leadGenerationPopup" v-if="isVisible">
        <div class="container">
            <img src="/alegefresh/images/ui/iqos-3-device.png" class="primary" alt="iqos-3-device">
            <div class="details">
                <button @click="togglePopup" />
                <h2>Îți mulțumim!</h2>
                <p>În cel mai scurt timp, unul dintre colegii noștri te va contacta pentru stabilirea unei întâlniri!
                    Până atunci, descoperă mai multe despre experiența fumatului, reinventată!</p>

                <a href="#beneficii" @click="togglePopup" class="primaryButton">Află mai multe despre IQOS</a>
            </div>
            <img src="/alegefresh/images/ui/iqos-3-device.png" class="secondary" alt="iqos-3-device">
        </div>
    </div>
</template>

<script>
    import {EventBus} from "../services/event-bus";

    export default {
        data(){
            return {
                isVisible: false
            }
        },
        mounted() {
            EventBus.$on('show-success-popup', () => {
                this.togglePopup();
            });
        },
        methods: {
            togglePopup() {
                this.isVisible = !this.isVisible;
            }
        }
    }
</script>
