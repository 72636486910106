<template>
    <div class="supportComponent">
        <h2>Dacă nu ai găsit ce căutai, ne poți contacta aici </h2>
        <hr/>

        <div class="container">
            <div class="columns">
                <div class="column">
                    <img src="/alegefresh/images/ui/svgs/live-chat-icon.svg" alt="asistenta-timp-real">
                    <h3>Asistență <br />în timp real</h3>
                    <p>Discută cu experții noștri în timp real, pe chat. Apasă pe “Live Chat” și începe conversația!
                        Program: zilnic, între <b>08:00-22:00</b></p>
                </div>

                <div class="column" v-on:click="goToURL('https://www.messenger.com/t/iqos.ro')">
                    <img src="/alegefresh/images/ui/svgs/facebook-icon.svg" alt="contact-facebook">
                    <h3>Trimite-ne un mesaj <br />pe Facebook</h3>
                    <p>Discută cu unul dintre experții noștri prin Facebook Messenger.
                        Program: zilnic, între <b>08:00 - 22:00</b></p>
                </div>

                <div class="column" v-on:click="goToURL('https://www.iqos.ro/iqos-intrebari-frecvente')">
                    <img src="/alegefresh/images/ui/svgs/faqs-icon.svg" alt="intrebari-frecvente">
                    <h3>Vezi întrebările <br />frecvente</h3>
                    <p>Găsește răspunsuri la cele mai frecvente întrebări despre produsele noastre.</p>
                </div>

                <div class="column" v-on:click="goToURL('https://www.iqos.ro/contact-iqos')">
                    <img src="/alegefresh/images/ui/svgs/email-icon.svg" alt="call-center-zilnic">
                    <h3>Trimite-ne <br />un email</h3>
                    <p>Trimite un mesaj pe email și facem tot posibilul să îți răspundem în cel mai scurt timp.
                        Program: zilnic, între <b>08:00 - 22:00</b></p>
                </div>

                <div class="column" v-on:click="goToURL('tel:+400800030333')">
                    <img src="/alegefresh/images/ui/svgs/phone-icon.svg" alt="call-center-zilnic">
                    <h3>Contactează-ne <br />telefonic</h3>
                    <p>Nu ai găsit încă răspunsul la întrebarea ta? Contactează-ne telefonic! Program: zilnic, între
                        <b>08:00 - 22:00</b>, la numărul <a href="tel:+400800030333">0800 030 333</a></p>
                </div>
            </div>
        </div>

        <div class="banner">
            <div class="container">
                <div class="details">
                    <h3>ÎNCEARCĂ IQOS GRATUIT, TIMP DE 14 ZILE, <br/> LA TINE ACASĂ</h3>
                    <a href="#form" class="primaryButton tryCTA">ÎNCEARCĂ IQOS GRATUIT</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VueScrollTo from "vue-scrollto";

    export default {
        methods: {
            scrollToForm() {
                VueScrollTo.scrollTo("#form", 600, {
                    offset: -50,
                });
            },
            goToURL(url) {
                window.open(url, '_blank');
            }
        }
    }
</script>
